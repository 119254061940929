import { FC } from 'react'
import Image from 'next/image'
import { Box, Theme, Typography, useMediaQuery } from '@mui/material'

import ClientInfoDesktop from '../ClientInfoDesktop'

import { TestimonialHead } from '../Testimonial/styled'

const Content: FC<IContent> = ({ testimonial, indicators }) => {
  const downSm = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'))

  return (
    <Box component="div" width="100%">
      <TestimonialHead>
        {testimonial.companyLogo && (
          <Image
            loader={({ src, width }) => `${src}?w=${width}`}
            src={testimonial.companyLogo.fullSize}
            alt={testimonial.company ? testimonial.company : 'company logo'}
            objectFit="contain"
            width={200}
            height={80}
            objectPosition={downSm ? 'center' : 'left'}
          />
        )}
        <Typography variant="body2" color="surface.100" sx={{ marginTop: 1 }}>
          {testimonial.tagline}
        </Typography>
      </TestimonialHead>

      <Typography variant="h5" component="p" sx={{ marginBottom: '1.5rem' }}>
        “{testimonial.text}”
      </Typography>

      {!downSm && <ClientInfoDesktop testimonial={testimonial} />}
      <Box component="div">{indicators}</Box>
    </Box>
  )
}

export default Content
