import { Theme, useMediaQuery } from '@mui/material'
import Stat from './Stat'
import { StatsContainer } from './styled'

export default function Stats({ projectStats, portfolio }: IStats) {
  const downSm = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'))

  return (
    <StatsContainer>
      {portfolio && downSm
        ? projectStats.slice(0, 3).map((stat, index) => <Stat key={index} stat={stat} />)
        : projectStats.map((stat, index) => <Stat key={index} stat={stat} />)}
    </StatsContainer>
  )
}
