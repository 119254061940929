import { Box, useMediaQuery, Theme } from '@mui/material'
import {
  DesktopContainer,
  MobileContainer,
  MobileLoaders,
  StyledSkeleton,
  TextLoaders,
} from './styled'

const LoadingState = () => {
  const downSm = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'))

  if (downSm) {
    return (
      <MobileContainer>
        <MobileLoaders>
          <StyledSkeleton variant="circular" width={42} height={42} />
          <Box
            component="div"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              alignItems: 'center',
            }}
          >
            <StyledSkeleton variant="circular" width={130} height={130} />
            <StyledSkeleton variant="rounded" width={200} height={20} />
            <StyledSkeleton variant="rounded" width={200} height={20} />
          </Box>
          <StyledSkeleton variant="circular" width={42} height={42} />
        </MobileLoaders>
        <StyledSkeleton variant="rectangular" width={210} height={90} />
        <StyledSkeleton variant="rounded" width="100%" height={20} />
        <StyledSkeleton variant="rounded" width="100%" height={20} />
        <StyledSkeleton variant="rounded" width="100%" height={20} />
        <StyledSkeleton variant="rounded" width="100%" height={20} />
      </MobileContainer>
    )
  }

  return (
    <DesktopContainer>
      <Box component="div">
        <StyledSkeleton variant="circular" width={220} height={220} />
      </Box>
      <Box component="div" sx={{ width: '100%' }}>
        <Box component="div" sx={{ marginBottom: 3.5 }}>
          <StyledSkeleton variant="rounded" width={178} height={24} sx={{ marginBottom: 1 }} />
          <StyledSkeleton variant="rounded" width={121} height={12} />
        </Box>

        <TextLoaders>
          <StyledSkeleton variant="rounded" width="100%" height={20} />
          <StyledSkeleton variant="rounded" width="100%" height={20} />
          <StyledSkeleton variant="rounded" width="100%" height={20} />
          <StyledSkeleton variant="rounded" width="100%" height={20} />
          <StyledSkeleton variant="rounded" width={262} height={20} />
        </TextLoaders>

        <Box component="div">
          <Box component="div" sx={{ display: 'flex', gap: 1, marginBottom: 1 }}>
            <StyledSkeleton variant="rounded" width={180} height={20} />
            <StyledSkeleton variant="rounded" width={20} height={20} />
          </Box>
          <StyledSkeleton variant="rounded" width={45} height={12} />
        </Box>
      </Box>
    </DesktopContainer>
  )
}

export default LoadingState
