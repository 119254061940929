import Image from 'next/image'
import { FC } from 'react'
import { trustedByConstants } from '../constants'
import { Container } from './styled'

const ImagesContainer: FC<IImagesContainer> = ({ trustedByList }) => {
  return (
    <Container>
      {trustedByList.map((company, index) => (
        <Image
          key={index}
          loader={({ src, width }) => `${src}?w=${width}`}
          src={company.logo.fullSize}
          alt={company.logoAltText}
          objectFit="contain"
          width={trustedByConstants.logoWidth}
          height={trustedByConstants.logoHeight}
          objectPosition="center"
          priority
        />
      ))}
    </Container>
  )
}

export default ImagesContainer
