import { Box, Typography, styled } from '@mui/material'

export const VerticalsIndicatorsContainer = styled(Box)(({ theme }) => ({
  margin: theme.spacing(0, 0, 3),
  display: 'flex',
  justifyContent: 'center',
  [theme.breakpoints.up('lg')]: {
    gap: theme.spacing(1.5),
    margin: theme.spacing(0, 0, 4.5),
    padding: theme.spacing(0, 3.5),
  },
}))

export const VerticalsIndicatorContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  flex: '1 1 0px',
  cursor: 'pointer',
  maxWidth: '175px',
}))

export const Title = styled(Typography)({
  display: 'flex',
  textAlign: 'center',
  justifyContent: 'center',
  alignItems: 'flex-end',
  flexGrow: 1,
}) as typeof Typography
