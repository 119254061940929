import { Box, Theme, useMediaQuery } from '@mui/material'
import Container from 'components/Container'
import { StyledSkeleton } from './styled'

const LoadingState = () => {
  const upSm = useMediaQuery<Theme>((theme) => theme.breakpoints.up('sm'))
  const upXl = useMediaQuery<Theme>((theme) => theme.breakpoints.up('xl'))

  const getBoxCount = () => {
    if (upXl) return 6
    if (upSm) return 4
    return 2
  }

  return (
    <Container>
      <Box component="div" sx={{ justifyContent: 'space-around', width: '100%' }}>
        <StyledSkeleton
          variant="rectangular"
          sx={{
            width: '200px',
            height: '24px',
            marginBottom: 6,
          }}
        />
        <Box component="div" display="flex" gap={3}>
          {[...Array(getBoxCount())].map((_, index) => (
            <StyledSkeleton
              key={index}
              variant="rectangular"
              sx={{
                height: '100px',
                flexGrow: 1,
              }}
            />
          ))}
        </Box>
      </Box>
    </Container>
  )
}

export default LoadingState
