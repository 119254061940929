import { Typography } from '@mui/material'
import SocialMedia from '../SocialMedia'
import { ClientInfoContainerDesktop, Container } from './styled'
import { FC } from 'react'

const ClientInfoDesktop: FC<ITestimonial> = ({ testimonial }) => {
  return (
    <ClientInfoContainerDesktop>
      <Container>
        <Typography variant="h6" component="p">
          {testimonial.clientName}
        </Typography>
        <SocialMedia testimonial={testimonial} />
      </Container>

      <Typography variant="body2">{testimonial.clientPosition}</Typography>
    </ClientInfoContainerDesktop>
  )
}

export default ClientInfoDesktop
