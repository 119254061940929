import { useCallback, useMemo, useRef } from 'react'
import { TypeAnimation } from 'react-type-animation'

import { useSkills } from '../hooks/useSkills'
import { useTheme } from '@mui/material'
import { useInView } from 'framer-motion'

const typeAnimationDelay = 2000

export const HeroTitleAnimation = () => {
  const { skills } = useSkills({ queryKey: 'heroSkills' })

  const theme = useTheme()
  const animationRef = useRef(null)
  const inView = useInView(animationRef, { amount: 0.5, margin: '-80px 0px 0px 0px' })

  const getTextSequence = useCallback(() => {
    const sequence: (string | number)[] = []

    skills.forEach((skill) => sequence.push(skill.title, typeAnimationDelay))

    return sequence
  }, [skills])

  const textSequence = useMemo(() => getTextSequence(), [getTextSequence])

  return (
    <span ref={animationRef}>
      {skills.length && inView ? (
        <TypeAnimation
          sequence={textSequence}
          speed={50}
          repeat={Infinity}
          style={{ color: theme.palette.primary.main }}
        />
      ) : (
        <span style={{ color: theme.palette.primary.main }}>ideas|</span>
      )}
    </span>
  )
}
