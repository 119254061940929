import { Box, styled } from '@mui/material'

export const TestimonialContainer = styled(Box)(({ theme }) => ({
  color: theme.palette.surface[100],
  display: 'flex',
  alignItems: 'center',
  minHeight: 400,
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}))

export const PersonalInfoContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  marginRight: 0,
  [theme.breakpoints.up('sm')]: {
    marginRight: theme.spacing(4),
  },
}))

export const ContentContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  padding: theme.spacing(0, 16),
  [theme.breakpoints.down('lg')]: {
    padding: theme.spacing(0, 4),
  },
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}))

export const TestimonialHead = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  display: 'block',
  flexDirection: 'column',
  alignItems: 'center',
  [theme.breakpoints.down('sm')]: {
    display: 'flex',
  },
}))

export const ButtonsContainerDesktop = styled(Box)(() => ({
  display: 'block',
  alignSelf: 'center',
}))

export const ButtonsContainerTablet = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1.25),
  marginTop: theme.spacing(2),
}))

export const ButtonsContainerMobile = styled(Box)(() => ({
  display: 'flex',
}))
