import 'react-responsive-carousel/lib/styles/carousel.min.css' // requires a loader
import { Carousel as ResponsiveCarousel } from 'react-responsive-carousel'
import { Box } from '@mui/material'
import {
  AnimationHandler,
  AnimationHandlerResponse,
} from 'react-responsive-carousel/lib/ts/components/Carousel/types'

const fadeAnimationHandler: AnimationHandler = (props, state): AnimationHandlerResponse => {
  const transitionTime = props.transitionTime + 'ms'
  const transitionTimingFunction = 'ease-in-out'

  let slideStyle: React.CSSProperties = {
    position: 'absolute',
    display: 'block',
    zIndex: -2,
    minHeight: '100%',
    opacity: 0,
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    transitionTimingFunction: transitionTimingFunction,
    msTransitionTimingFunction: transitionTimingFunction,
    MozTransitionTimingFunction: transitionTimingFunction,
    WebkitTransitionTimingFunction: transitionTimingFunction,
    OTransitionTimingFunction: transitionTimingFunction,
  }

  if (!state.swiping) {
    slideStyle = {
      ...slideStyle,
      WebkitTransitionDuration: transitionTime,
      MozTransitionDuration: transitionTime,
      OTransitionDuration: transitionTime,
      transitionDuration: transitionTime,
      msTransitionDuration: transitionTime,
    }
  }

  return {
    slideStyle,
    selectedStyle: { ...slideStyle, zIndex: 1, opacity: 1, position: 'relative' },
    prevStyle: { ...slideStyle },
  }
}

export default function Carousel({ children, index }: ITimedCarouselProps) {
  return (
    <ResponsiveCarousel
      autoPlay={false}
      showArrows={false}
      swipeable={false}
      showIndicators={false}
      showStatus={false}
      showThumbs={false}
      renderItem={(item) => (
        <Box component="div" textAlign="left">
          {item}
        </Box>
      )} // overriding default textAlign value
      selectedItem={index}
      animationHandler={fadeAnimationHandler}
    >
      {children}
    </ResponsiveCarousel>
  )
}
