import { Euler, Vector3 } from 'three'

export const deviceProps = {
  xs: {
    position: new Vector3(-0.5, 2, -2),
    rotation: new Euler(-0.07, 0, 0),
    scale: 3,
  },
  sm: {
    position: new Vector3(0, 2, -2),
    rotation: new Euler(-0.03, 0, 0),
    scale: 4,
  },
  md: {
    position: new Vector3(0, 4, 0),
    rotation: new Euler(-0, 0, 0),
    scale: 5,
  },
  lg: {
    position: new Vector3(0, 4, 0),
    rotation: new Euler(-0.03, 0, 0),
    scale: 6,
  },
  xl: {
    position: new Vector3(0, 4, 0),
    rotation: new Euler(-0.03, 0, 0),
    scale: 6,
  },
}

export const modelFinalStates = {
  xs: {
    finalModelXPosition: 0,
    finalModelYPosition: 0.65,
    finalModelYRotation: -0.1,
    finalScreenXRotation: -0.2,
    finalModelScale: 2,
  },
  sm: {
    finalModelXPosition: 0,
    finalModelYPosition: -0.25,
    finalModelYRotation: -0.1,
    finalScreenXRotation: -0.2,
    finalModelScale: 2.4,
  },
  md: {
    finalModelXPosition: 3.5,
    finalModelYPosition: 3,
    finalModelYRotation: -0.2,
    finalScreenXRotation: -0.1,
    finalModelScale: 3,
  },
  lg: {
    finalModelXPosition: 3.5,
    finalModelYPosition: 2,
    finalModelYRotation: -0.5,
    finalScreenXRotation: -0.1,
    finalModelScale: 4,
  },
  xl: {
    finalModelXPosition: 3,
    finalModelYPosition: 1.5,
    finalModelYRotation: -0.5,
    finalScreenXRotation: -0.1,
    finalModelScale: 4.5,
  },
}
