import { Box, Skeleton, styled } from '@mui/material'

export const StyledSkeleton = styled(Skeleton)(({ theme }) => ({
  backgroundColor: theme.palette.surface[900],
}))

export const DesktopContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(4),
  margin: `0 ${theme.spacing(4)}`,
}))

export const MobileContainer = styled(Box)(({ theme }) => ({
  flexDirection: 'column',
  alignItems: 'center',
  margin: `0 ${theme.spacing(4)}`,
  gap: theme.spacing(1),
  display: 'flex',
}))

export const MobileLoaders = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})

export const TextLoaders = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1.2),
  marginBottom: theme.spacing(3),
}))
