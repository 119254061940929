/* eslint react/no-unknown-property: 0 */

import { Suspense } from 'react'
import { Html, Loader } from '@react-three/drei'
import { useTheme } from '@mui/material'

import Laptop from './Laptop'
import { Scene } from 'components/3DScene'
import { Canvas } from 'components/3DScene'

import { LaptopContainer } from './styled'

const HeroCanvas = () => {
  const theme = useTheme()

  return (
    <LaptopContainer>
      <Canvas>
        <spotLight position={[0, 10, 40]} intensity={0.4} />
        <Scene
          cameraProps={{ position: [0, 1, 30], near: 0.1, far: 100, zoom: 2 }}
          spotlightProps={{ position: [0, 20, 5], intensity: 1.5 }}
        />
        <Suspense
          fallback={
            <Html>
              <Loader
                barStyles={{ background: theme.palette.primary.main }}
                dataInterpolation={(p) => `Loading ${p.toFixed(0)}%`}
                containerStyles={{ background: theme.palette.surface.A900 }}
              />
            </Html>
          }
        >
          <Laptop />
        </Suspense>
      </Canvas>
    </LaptopContainer>
  )
}

export default HeroCanvas
