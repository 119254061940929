function FacebookIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 0C0.895431 0 0 0.895431 0 2V14C0 15.1046 0.895431 16 2 16H14C15.1046 16 16 15.1046 16 14V2C16 0.895431 15.1046 0 14 0H2ZM11.75 7.875L11.3953 10.1875H9.53125V15.7781C9.12344 15.8422 8.70625 15.875 8.28125 15.875C7.85625 15.875 7.43906 15.8422 7.03125 15.7781V10.1875H5V7.875H7.03125V6.1125C7.03125 4.10781 8.225 3 10.0531 3C10.9281 3 11.8438 3.15625 11.8438 3.15625V5.125H10.8344C9.84063 5.125 9.53125 5.74219 9.53125 6.375V7.875H11.75Z"
        fill="#AFBCC4"
      />
    </svg>
  )
}

export default FacebookIcon
