import { Indicator } from '../../TimedCarousel/Indicators/styled'
import { VerticalsIndicatorContainer, Title } from './styled'

export default function VerticalsIndicators({
  verticals,
  currentSlide,
  currentTimePercentage,
  setSlide,
}: IVerticalsIndicatorsProps) {
  const getIndicatorFill = (index: number) => {
    if (index < currentSlide) {
      return 100
    }

    if (index === currentSlide) {
      return currentTimePercentage
    }

    return 0
  }

  return (
    <>
      {verticals?.map((vertical, index) => (
        <VerticalsIndicatorContainer onClick={() => setSlide(index)} key={vertical.slug}>
          <Title variant="h6" component="p">
            {vertical.title}
          </Title>
          <Indicator key={index} fill={getIndicatorFill(index)} sx={{ flexGrow: 0 }} />
        </VerticalsIndicatorContainer>
      ))}
    </>
  )
}
