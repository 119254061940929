import { styled, Box, Typography } from '@mui/material'

export const VerticalsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: theme.palette.surface['A900'],
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(12),
  [theme.breakpoints.up('md')]: {
    marginTop: theme.spacing(5),
  },
  [theme.breakpoints.up('lg')]: {
    marginTop: theme.spacing(10),
  },
}))

export const VerticalsTitle = styled(Typography)(({ theme }) => ({
  ...theme.typography.h2,
  textAlign: 'center',
  marginBottom: theme.spacing(4),
  [theme.breakpoints.up('lg')]: {
    ...theme.typography.titleBold,
  },
  [theme.breakpoints.up('xl')]: {
    ...theme.typography.display,
  },
})) as typeof Typography
