import { Box, styled } from '@mui/material'

export const StatsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'nowrap',
  gap: theme.spacing(3),
  marginBottom: theme.spacing(3),
  [theme.breakpoints.up('lg')]: {
    marginBottom: theme.spacing(7),
    maxWidth: '500px',
    flexWrap: 'wrap',
  },
  [theme.breakpoints.up('xl')]: {
    marginBottom: theme.spacing(12),
  },
}))
