import { useEffect, useMemo, useRef, useState } from 'react'
import { motion, useInView } from 'framer-motion'
import { Box } from '@mui/material'

import Project from './Project'
import Loading from './Loading'
import ProjectCanvas from './ProjectCanvas'
import Container from 'components/Container'
import Indicators from 'components/TimedCarousel/Indicators'
import useTimedCarousel from 'components/TimedCarousel/hooks/useTimedCarousel'
import Carousel from 'components/TimedCarousel'

import { IndicatorsContainer, ProjectsContainer } from './styled'

import { useProjects } from 'hooks/projects/useProjects'
import { projectsCarouselId } from 'shared/constants'

export default function Projects() {
  const containerRef = useRef(null)
  const [currentVideoLength, setCurrentVideoLength] = useState(10000)

  const { projects, isLoadingProjects } = useProjects({ apiParams: { expand: 'stats' } })
  const featuredProjects = useMemo(
    () => projects.filter((project) => project.isFeatured),
    [projects],
  )

  const inView = useInView(containerRef, { amount: 0.5 })

  const getCurrentVideoLength = (videoElement: HTMLVideoElement) => {
    setCurrentVideoLength(videoElement.duration * 1000 || 10000)
  }

  const {
    currentSlide,
    currentTimePercentage,
    prevSlide,
    nextSlide,
    setSlide,
    resumeTimer,
    pauseTimer,
  } = useTimedCarousel(featuredProjects.length, () => currentVideoLength)

  useEffect(() => {
    if (inView) {
      resumeTimer()
    } else {
      pauseTimer()
    }
  }, [inView, resumeTimer, pauseTimer])

  return (
    <Box component="div" sx={{ position: 'relative', width: '100%' }} id={projectsCarouselId}>
      <Container>
        <ProjectsContainer ref={containerRef}>
          {isLoadingProjects || !featuredProjects.length ? (
            <Loading />
          ) : (
            <>
              <IndicatorsContainer>
                <Indicators
                  count={featuredProjects.length}
                  currentSlide={currentSlide}
                  currentTimePercentage={currentTimePercentage}
                  setSlide={setSlide}
                />
              </IndicatorsContainer>
              <Carousel index={currentSlide}>
                {featuredProjects.map((project) => (
                  <Project
                    key={project.id}
                    project={project}
                    controlsProps={{
                      leftDisabled: currentSlide === 0,
                      rightDisabled: currentSlide === featuredProjects.length - 1,
                      prevSlide,
                      nextSlide,
                    }}
                  />
                ))}
              </Carousel>
            </>
          )}
          {featuredProjects[currentSlide] ? (
            <motion.div
              key={currentSlide}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 2 }}
            >
              <ProjectCanvas
                getCurrentVideoLength={getCurrentVideoLength}
                currentProject={featuredProjects[currentSlide]}
                inView={inView}
              />
            </motion.div>
          ) : null}
        </ProjectsContainer>
      </Container>
    </Box>
  )
}
