import { useEffect, useRef } from 'react'
import { Box } from '@mui/material'
import { useQuery } from '@baseapp-frontend/core'

import Testimonial from './Testimonial'
import LoadingState from './LoadingState'
import Carousel from 'components/TimedCarousel'
import Indicators from 'components/TimedCarousel/Indicators'
import useTimedCarousel from 'components/TimedCarousel/hooks/useTimedCarousel'
import Container from 'components/Container'

import TestimonialsApi from 'api/TestimonialsApi'
import { useInView } from 'framer-motion'

const CAROUSEL_DELAY = 50000 // ms

const TestimonialsSection = () => {
  const containerRef = useRef(null)

  const { data, isFetching } = useQuery('testimonials', () => TestimonialsApi.getTestimonials())
  const testimonials = data?.results || []

  const {
    currentSlide,
    currentTimePercentage,
    prevSlide,
    nextSlide,
    setSlide,
    pauseTimer,
    resumeTimer,
  } = useTimedCarousel(testimonials.length, () => CAROUSEL_DELAY)

  const inView = useInView(containerRef, { amount: 0.5 })

  useEffect(() => {
    if (inView) {
      resumeTimer()
    } else {
      pauseTimer()
    }
  }, [inView, pauseTimer, resumeTimer])

  return (
    <Container>
      <Box component="div" ref={containerRef}>
        {isFetching ? (
          <LoadingState />
        ) : (
          <Carousel index={currentSlide}>
            {testimonials.map((testimonial, index) => (
              <Testimonial
                key={index}
                testimonial={testimonial}
                controlsProps={{
                  leftDisabled: currentSlide === 0,
                  rightDisabled: currentSlide === testimonials.length - 1,
                  prevSlide,
                  nextSlide,
                }}
                indicators={
                  <Indicators
                    count={testimonials.length}
                    currentSlide={currentSlide}
                    currentTimePercentage={currentTimePercentage}
                    setSlide={setSlide}
                  />
                }
              />
            ))}
          </Carousel>
        )}
      </Box>
    </Container>
  )
}

export default TestimonialsSection
