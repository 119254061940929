import { Box, styled } from '@mui/material'

export const ClientInfoContainerDesktop = styled(Box)(({ theme }) => ({
  display: 'block',
  marginBottom: theme.spacing(4),
}))

export const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1.5),
}))
