import { Box, Skeleton, styled } from '@mui/material'

export const SkeletonContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.up('lg')]: {
    margin: 0,
    width: '50%',
  },
}))

export const StyledSkeleton = styled(Skeleton)(({ theme }) => ({
  backgroundColor: theme.palette.surface['900'],
}))

export const ButtonSkeleton = styled(StyledSkeleton)(({ theme }) => ({
  width: theme.spacing(15),
  height: theme.spacing(8),
  borderRadius: '24px',
}))

export const IndicatorsSkeleton = styled(StyledSkeleton)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  [theme.breakpoints.up('md')]: {
    order: 3,
    marginTop: theme.spacing(2),
  },
}))
