import { Box, styled, Typography } from '@mui/material'
import Container from 'components/Container'
import Button from 'components/Button'
import { preventForwardProp } from 'utils/styledUtils'
import { IBox100vh } from './types'

export const WrapperContainer = styled(Box)(() => ({
  display: 'block',
  height: `calc(100vh - 80px)`,
  width: '100%',
}))

export const HeroContainer = styled(
  Box,
  preventForwardProp('height100vh'),
)<IBox100vh>(({ theme, height100vh }) => {
  let strHeight100vh

  // Viewport heights lower than 610px won't use DVH to display the "book a call" button.
  if (height100vh && height100vh > 610) {
    strHeight100vh = `${height100vh}px`
  } else {
    strHeight100vh = '100vh'
  }

  return {
    display: 'block',
    height: `calc(${strHeight100vh} - 80px)`,
    padding: theme.spacing(2, 0, 4),
    maxHeight: '840px',
    width: '100%',
    position: 'relative',
    transition: '0.5s ease',
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(6, 0, 4),
    },
    [theme.breakpoints.up(1700)]: {
      minHeight: `calc(${strHeight100vh} - 80px)`,
    },
    [theme.breakpoints.up(1900)]: {
      background: '1050px',
    },
  }
})

export const MainContentContainer = styled(Box)(({ theme }) => ({
  zIndex: 1,
  position: 'absolute',
  width: '100%',
  [theme.breakpoints.up(1700)]: {
    top: '18%',
  },
  [theme.breakpoints.up(2500)]: {
    top: '27%',
  },
  [theme.breakpoints.up(3000)]: {
    top: '32%',
  },
}))

export const StyledContainer = styled(Container)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: `calc(100% - 45px)`,
  [theme.breakpoints.up('md')]: {
    height: `calc(100% - 75px)`,
  },
}))

export const TextContainer = styled(Box)(({ theme }) => ({
  minHeight: '280px',
  [theme.breakpoints.up('lg')]: {
    minHeight: '337px',
  },
  [theme.breakpoints.up('xl')]: {
    minHeight: '385px',
  },
}))

export const Title = styled((props) => <Typography component="h1" {...props} />)(({ theme }) => ({
  ...theme.typography.h2,
  fontWeight: 700,
  maxWidth: '400px',
  width: '100%',
  [theme.breakpoints.up('lg')]: {
    ...theme.typography.displaySm,
  },
  [theme.breakpoints.up('xl')]: {
    ...theme.typography.display,
    maxWidth: '400px',
  },
  '@media (max-height: 700px)': {
    ...theme.typography.h3,
  },
})) as typeof Typography

export const Subtitle = styled(Typography)(({ theme }) => ({
  ...theme.typography.h5,
  display: 'block',
  marginTop: theme.spacing(1),
  [theme.breakpoints.up('md')]: {
    ...theme.typography.h4,
    marginTop: theme.spacing(2),
  },
})) as typeof Typography

export const ButtonContainer = styled(Box)(() => ({
  marginTop: '28px',
}))

export const ArrowContainer = styled(
  Box,
  preventForwardProp('height100vh'),
)<IBox100vh>(({ theme, height100vh }) => {
  if (!height100vh) {
    return { display: 'none' }
  }
  return {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    gap: theme.spacing(1.5),
    width: '100%',
    marginTop: 'auto',
    zIndex: 1,
  }
})

export const BookACallButton = styled(Button)(({ theme }) => ({
  visibility: 'visible',
  [theme.breakpoints.up('md')]: {
    visibility: 'hidden',
  },
}))

export const ArrowBox = styled(Box)(({ theme }) => ({
  display: 'none',
  cursor: 'pointer',
  zIndex: 2,
  [theme.breakpoints.up('md')]: {
    display: 'block',
  },
}))
