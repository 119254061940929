import { Typography } from '@mui/material'
import { FC } from 'react'
import SocialMedia from '../SocialMedia'
import { ClientInfoContainerMobile, ClientName, ProfessionalInfoContainer } from './styled'

const ClientInfo: FC<ITestimonial> = ({ testimonial }) => {
  return (
    <ClientInfoContainerMobile>
      <ClientName variant="h6" color="surface.100">
        {testimonial.clientName}
      </ClientName>

      <ProfessionalInfoContainer>
        <SocialMedia testimonial={testimonial} />
        <Typography variant="body2" color="surface.100">
          {testimonial.clientPosition}
        </Typography>
      </ProfessionalInfoContainer>
    </ClientInfoContainerMobile>
  )
}

export default ClientInfo
