import { Box, styled, Typography } from '@mui/material'

export const ClientInfoContainerMobile = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(1.5),
  marginRight: theme.spacing(1),
  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginRight: 0,
  },
}))

export const ProfessionalInfoContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1.5),
  alignItems: 'center',
  marginBottom: theme.spacing(1.2),
  [theme.breakpoints.down('sm')]: {
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(4),
  },
}))

export const ClientName = styled(Typography)(({ theme }) => ({
  textAlign: 'left',
  [theme.breakpoints.down('sm')]: {
    textAlign: 'center',
  },
}))
