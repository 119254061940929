import { Box } from '@mui/material'

import { Value, ValueSymbol, Description } from './styled'

export default function Stat({ stat }: { stat: IStat }) {
  return (
    <Box component="div">
      <Box component="div" display="flex" alignItems="end" flexWrap="wrap">
        <Value>{stat.value}</Value>
        {stat.valueSymbol ? <ValueSymbol>{stat.valueSymbol}</ValueSymbol> : null}
      </Box>
      <Description>{stat.description}</Description>
    </Box>
  )
}
