import { Box, Link as MUILink, styled } from '@mui/material'

export const Link = styled(MUILink)(({ theme }) => ({
  color: theme.palette.surface['300'],
  cursor: 'pointer',
  lineHeight: 0,
}))

export const ActionsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1.5),
  ' svg': {
    width: '20px',
    height: '20px',
  },
}))
