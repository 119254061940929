import { useEffect, useMemo, useState } from 'react'
import { useQuery } from '@baseapp-frontend/core'
import { Box } from '@mui/material'

import { trustedByConstants } from './constants'
import ImagesContainer from './ImagesContainer'
import LoadingState from './LoadingState'
import { GradientContainer, TrustedByContainer, TrustedByTitle, TrustedByWrapper } from './styled'

import TrustedByApi from 'api/TrustedByApi'
import { trustedBySectionId } from 'shared/constants'

const TrustedBySection = () => {
  const { data, isFetching } = useQuery('trustedBy', () => TrustedByApi.getTrustedBy())

  const [imagesContainerWidth, setImagesContainerWidth] = useState(0)
  const [finalWidthOdd, setFinalWidthOdd] = useState(0)
  const [finalWidthEven, setFinalWidthEven] = useState(0)

  const trustedByFirstRow = useMemo(() => (data ? data.firstRow : []), [data])
  const trustedBySecondRow = useMemo(() => (data ? data.secondRow : []), [data])

  const trustedBy = useMemo(() => {
    return [
      { elements: trustedByFirstRow, width: finalWidthEven },
      { elements: trustedBySecondRow, width: finalWidthOdd },
    ]
  }, [trustedBySecondRow, trustedByFirstRow, finalWidthOdd, finalWidthEven])

  useEffect(() => {
    const length = data?.fullArray.length || 0

    setImagesContainerWidth(length * trustedByConstants.logoWidth)
    window.addEventListener('resize', () => {
      setImagesContainerWidth(length * trustedByConstants.logoWidth)
    })

    return () => {
      window.removeEventListener('resize', () => {
        setImagesContainerWidth(length * trustedByConstants.logoWidth)
      })
    }
  }, [data])

  useEffect(() => {
    const containerWidth =
      trustedByFirstRow.length > 0 ? ((imagesContainerWidth * 100) / window.innerWidth) * 2 : 0

    const newWidth =
      containerWidth < 200
        ? 200 + trustedByFirstRow.length * (trustedByConstants.gap / 4)
        : containerWidth + trustedByFirstRow.length * trustedByConstants.gap
    setFinalWidthEven(newWidth)
  }, [imagesContainerWidth, trustedByFirstRow.length])

  useEffect(() => {
    const containerWidth =
      trustedBySecondRow.length > 0 ? ((imagesContainerWidth * 100) / window.innerWidth) * 2 : 0

    const newWidth =
      containerWidth < 200
        ? 200 + trustedBySecondRow.length * (trustedByConstants.gap / 4)
        : containerWidth + trustedBySecondRow.length * trustedByConstants.gap
    setFinalWidthOdd(newWidth)
  }, [imagesContainerWidth, trustedBySecondRow.length])

  return (
    <Box component="div" id={trustedBySectionId}>
      {isFetching || !trustedBy.length ? (
        <LoadingState />
      ) : (
        <TrustedByWrapper>
          <TrustedByTitle variant="h3" component="h2">
            Trusted by
          </TrustedByTitle>
          {trustedBy.map(({ elements, width }, index) => (
            <GradientContainer key={index}>
              <TrustedByContainer
                direction={index % 2 === 0 ? 'right' : 'left'}
                finalWidth={width}
                elements={elements.length}
              >
                <ImagesContainer trustedByList={elements} />
                <ImagesContainer trustedByList={elements} />
                <ImagesContainer trustedByList={elements} />
                <ImagesContainer trustedByList={elements} />
              </TrustedByContainer>
            </GradientContainer>
          ))}
        </TrustedByWrapper>
      )}
    </Box>
  )
}

export default TrustedBySection
