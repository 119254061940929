import { Link, ActionsContainer } from './styled'
import { FC } from 'react'
import LinkedInIcon from 'components/Icons/LinkedInIcon'
import InstagramIcon from 'components/Icons/InstagramIcon'
import TwitterIcon from 'components/Icons/TwitterIcon'
import FacebookIcon from 'components/Icons/FacebookIcon'

const SocialMedia: FC<ITestimonial> = ({ testimonial }) => {
  return (
    <ActionsContainer>
      {testimonial && testimonial.linkedinUrl && (
        <Link
          href={testimonial.linkedinUrl}
          target="_blank"
          aria-label={`${testimonial.clientName}'s Linkedin account`}
        >
          <LinkedInIcon />
        </Link>
      )}

      {testimonial && testimonial.instagramUrl && (
        <Link
          href={testimonial.instagramUrl}
          target="_blank"
          aria-label={`${testimonial.clientName}'s Instagram account`}
        >
          <InstagramIcon />
        </Link>
      )}

      {testimonial && testimonial.twitterUrl && (
        <Link
          href={testimonial.twitterUrl}
          target="_blank"
          aria-label={`${testimonial.clientName}'s Twitter account`}
        >
          <TwitterIcon />
        </Link>
      )}

      {testimonial && testimonial.facebookUrl && (
        <Link
          href={testimonial.facebookUrl}
          target="_blank"
          aria-label={`${testimonial.clientName}'s Facebook account`}
        >
          <FacebookIcon />
        </Link>
      )}
    </ActionsContainer>
  )
}

export default SocialMedia
