/* eslint react/no-unknown-property: 0 */

import { useCallback, useMemo, useRef } from 'react'
import { Theme, useMediaQuery } from '@mui/material'
import { useFrame } from '@react-three/fiber'
import { Group, MathUtils, Quaternion, Vector3 } from 'three'

import LaptopModel from 'public/assets/3dmodels/laptop/LaptopModel'
import { IScreenMedia } from 'public/assets/3dmodels/types'

import { deviceProps, modelFinalStates } from './constants'

const Laptop = () => {
  const modelRef = useRef<Group | null>(null)
  const screenRef = useRef<Group | null>(null)
  const groupRef = useRef<Group | null>(null)

  const upSm = useMediaQuery<Theme>((theme) => theme.breakpoints.up('sm'))
  const upMd = useMediaQuery<Theme>((theme) => theme.breakpoints.up('md'))
  const upLg = useMediaQuery<Theme>((theme) => theme.breakpoints.up('lg'))
  const upXl = useMediaQuery<Theme>((theme) => theme.breakpoints.up('xl'))

  const screenMedia = useMemo(
    () =>
      ({
        type: 'image',
        src: '/assets/tsl-team-france.webp', // should be a marketing video
      } as IScreenMedia),
    [],
  )

  const getDeviceProps = useCallback(() => {
    if (upXl) return deviceProps.xl
    if (upLg) return deviceProps.lg
    if (upMd) return deviceProps.md
    if (upSm) return deviceProps.sm

    return deviceProps.xs
  }, [upSm, upMd, upLg, upXl])

  const getModelFinalPositions = useCallback(() => {
    if (upXl) return modelFinalStates.xl
    if (upLg) return modelFinalStates.lg
    if (upMd) return modelFinalStates.md
    if (upSm) return modelFinalStates.sm

    return modelFinalStates.xs
  }, [upSm, upMd, upLg, upXl])

  const finalStates = useMemo(() => getModelFinalPositions(), [getModelFinalPositions])

  useFrame(({ clock, pointer }, delta) => {
    if (modelRef.current && screenRef.current && groupRef.current && clock.elapsedTime > 2) {
      const {
        finalModelScale,
        finalModelXPosition,
        finalModelYPosition,
        finalModelYRotation,
        finalScreenXRotation,
      } = finalStates

      const model = modelRef.current
      const screen = screenRef.current
      const group = groupRef.current

      // rotate laptop to follow the cursor
      const smoothness = 4 * delta
      const targetGroupQuaternion = new Quaternion().setFromAxisAngle(
        new Vector3(-pointer.y, pointer.x, 0),
        0.01,
      )
      group.quaternion.slerp(targetGroupQuaternion, smoothness)

      // laptop animation
      model.rotation.set(
        model.rotation.x,
        MathUtils.damp(model.rotation.y, finalModelYRotation, 1, delta),
        model.rotation.z,
      )
      model.position.set(
        MathUtils.damp(model.position.x, finalModelXPosition, 1, delta),
        MathUtils.damp(model.position.y, finalModelYPosition, 1, delta),
        model.position.z,
      )
      model.scale.setScalar(MathUtils.damp(model.scale.x, finalModelScale, 1, delta))
      screen.rotation.set(
        MathUtils.damp(screen.rotation.x, finalScreenXRotation, 1, delta),
        screen.rotation.y,
        screen.rotation.z,
      )
    }
  })

  return (
    <group ref={groupRef}>
      <LaptopModel
        {...getDeviceProps()}
        screenMedia={screenMedia}
        screenProps={{
          rotation: [1.638, 0, 0],
        }}
        setRef={(ref) => {
          modelRef.current = ref
        }}
        setScreenRef={(ref) => {
          screenRef.current = ref
        }}
      />
    </group>
  )
}

export default Laptop
