import { Box, styled } from '@mui/material'

export const LaptopContainer = styled(Box)(({ theme }) => ({
  position: 'absolute',
  width: '100%',
  maxWidth: '1900px',
  height: '100vh',
  top: 0,
  left: '50%',
  transform: 'translateX(-50%)',
  zIndex: 0,
  maxHeight: '840px',
  [theme.breakpoints.up(1700)]: {
    maxHeight: '920px',
  },
  [theme.breakpoints.up(1900)]: {
    background: '1050px',
    top: '2%',
  },
  [theme.breakpoints.up(2500)]: {
    top: '20%',
  },
  [theme.breakpoints.up(3000)]: {
    top: '25%',
  },
}))
