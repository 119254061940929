import type { NextPage } from 'next'
import { Box } from '@mui/material'
import { dehydrate, QueryClient } from 'react-query'

import AwardsSection from 'components/AwardsSection'
import Projects from 'components/Projects'
import Verticals from 'components/Verticals'
import TestimonialsSection from 'components/TestimonialsSection'
import Hero from 'components/Hero'
import ServicesPage from 'components/Services'
import MetaTags from 'components/MetaTags'
import { SnapScroll } from 'components/SnapScroll/styled'
import { defaultMeta } from 'shared/constants'

import SkillsApi from 'api/SkillsApi'
import ServicesApi from 'api/ServicesApi'
import VerticalsApi from 'api/VerticalsApi'
import TestimonialsApi from 'api/TestimonialsApi'
import AwardsApi from 'api/AwardsApi'
import TrustedBySection from 'components/TrustedBySection'

export async function getServerSideProps() {
  const queryClient = new QueryClient()

  await Promise.all([
    queryClient.prefetchQuery(['skills'], () => SkillsApi.getSkills()),
    queryClient.prefetchQuery(['verticals'], () => VerticalsApi.getVerticals()),
    queryClient.prefetchQuery(['testimonials'], () => TestimonialsApi.getTestimonials()),
    queryClient.prefetchQuery(['awards'], () => AwardsApi.getAwards()),
    queryClient.prefetchQuery(['services'], () => ServicesApi.list()),
    queryClient.prefetchQuery(['default-services-background'], () =>
      ServicesApi.getDefaultBackground(),
    ),
    queryClient.prefetchQuery(['auto-rotate-services-timer'], () =>
      ServicesApi.getAutoRotateTimer(),
    ),
  ])

  return {
    props: {
      dehydratedState: dehydrate(queryClient),
    },
  }
}

const Home: NextPage = () => {
  return (
    <Box component="div" sx={{ backgroundColor: 'surface.A900' }}>
      <MetaTags data={defaultMeta} />
      <SnapScroll>
        <Hero />
      </SnapScroll>
      <SnapScroll>
        <Box component="div" width="100%" mt={9} mb={9}>
          <TrustedBySection />
        </Box>
      </SnapScroll>
      <SnapScroll>
        <Box component="div" width="100%" mt={9} mb={9}>
          <Projects />
        </Box>
      </SnapScroll>
      <SnapScroll>
        <Box component="div" width="100%" mt={9} mb={9}>
          <ServicesPage />
        </Box>
      </SnapScroll>
      <SnapScroll>
        <Box component="div" width="100%" mt={9} mb={9}>
          <Verticals />
        </Box>
      </SnapScroll>
      <SnapScroll>
        <Box component="div" width="100%" mt={9} mb={9}>
          <AwardsSection />
        </Box>
      </SnapScroll>
      <SnapScroll>
        <TestimonialsSection />
      </SnapScroll>
    </Box>
  )
}

export default Home
