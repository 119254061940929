import { Box, Typography } from '@mui/material'

import { ControlsContainer, CTAContainer } from '../Project/styled'
import { ButtonSkeleton, IndicatorsSkeleton, SkeletonContainer, StyledSkeleton } from './styled'

export default function Loading() {
  return (
    <SkeletonContainer>
      <IndicatorsSkeleton variant="rectangular" />
      <Box component="div">
        <Typography variant="caption">
          <StyledSkeleton />
        </Typography>
        <Typography variant="h2" component="h2" sx={{ marginBottom: 3 }}>
          <StyledSkeleton />
        </Typography>
        <StyledSkeleton variant="rectangular" sx={{ marginBottom: 2, height: 80 }} />
        <StyledSkeleton variant="rectangular" sx={{ marginBottom: 4, height: 64 }} />
        <Box component="div" display="flex" flexWrap="wrap" alignItems="center">
          <ControlsContainer sx={{ marginBottom: 0 }}>
            <Box component="div" display="flex" gap="16px">
              <StyledSkeleton variant="circular" sx={{ width: 42, height: 42 }} />
              <StyledSkeleton variant="circular" sx={{ width: 42, height: 42 }} />
            </Box>
          </ControlsContainer>
          <CTAContainer>
            <ButtonSkeleton />
            <ButtonSkeleton />
          </CTAContainer>
        </Box>
      </Box>
    </SkeletonContainer>
  )
}
