import Image from 'next/image'
import { AvatarContainer } from './styled'

const Avatar = ({ clientPhoto, imageAltText }: { clientPhoto: string; imageAltText: string }) => {
  return (
    <AvatarContainer>
      <Image
        loader={({ src, width }) => `${src}?w=${width}`}
        src={clientPhoto}
        layout="fill"
        objectFit="cover"
        alt={imageAltText}
        title={imageAltText}
      />
    </AvatarContainer>
  )
}

export default Avatar
