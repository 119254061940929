import { StyledIconButton } from 'components/TimedCarousel/Controls/styled'
import { FC } from 'react'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'

const Buttons: FC<IButtonsProps> = ({ controlsProps, leftSide }) => {
  return (
    <>
      {leftSide ? (
        <StyledIconButton
          aria-label="previous-slide"
          size="large"
          onClick={controlsProps.prevSlide}
          disabled={controlsProps.leftDisabled}
          title="Previous Slide"
        >
          <KeyboardArrowLeftIcon />
        </StyledIconButton>
      ) : (
        <StyledIconButton
          aria-label="next-slide"
          size="large"
          onClick={controlsProps.nextSlide}
          disabled={controlsProps.rightDisabled}
          title="Next Slide"
        >
          <KeyboardArrowRightIcon />
        </StyledIconButton>
      )}
    </>
  )
}

export default Buttons
