import { Box, styled, Typography, TypographyProps } from '@mui/material'
import { preventForwardProp } from 'utils/styledUtils'
import { ITagline } from './types'

export const ProjectWrapper = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr',
  gridTemplateRows: '1fr',
  [theme.breakpoints.up('lg')]: {
    width: '45%',
  },
}))

export const ControlsWrapper = styled(Box)(() => ({
  display: 'flex',
  flexWrap: 'wrap',
}))

export const ControlsContainer = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(2),
  flexBasis: '100%',
  [theme.breakpoints.up('sm')]: {
    marginTop: 0,
    marginLeft: theme.spacing(2),
    flexBasis: 'unset',
  },
}))

export const CTAContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
  height: '42px',
}))

export const Subtitle = styled(Typography)(({ theme }) => ({
  ...theme.typography.caption,
  marginBottom: 0,
  [theme.breakpoints.up('sm')]: {
    ...theme.typography.subtitle2,
  },
  [theme.breakpoints.up('lg')]: {
    ...theme.typography.subtitle1,
  },
})) as typeof Typography

export const Title = styled((props) => <Typography component="h1" {...props} />)<TypographyProps>(
  ({ theme }) => ({
    ...theme.typography.h3,
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      ...theme.typography.title,
      marginBottom: theme.spacing(3),
    },
    [theme.breakpoints.up('md')]: {
      ...theme.typography.display,
    },
    [theme.breakpoints.up('lg')]: {
      ...theme.typography.titleBold,
    },
    [theme.breakpoints.up('xl')]: {
      ...theme.typography.display,
    },
  }),
) as typeof Typography

export const Tagline = styled(
  Typography,
  preventForwardProp('portfolio'),
)<ITagline>(({ theme, portfolio }) => ({
  ...theme.typography.body2,

  marginBottom: theme.spacing(2.25),
  [theme.breakpoints.down('sm')]: {
    overflow: portfolio && 'hidden',
    textOverflow: portfolio && 'ellipsis',
    maxHeight: portfolio && '140px',
    display: portfolio && '-webkit-box',
    '-webkit-line-clamp': portfolio && '7',
    '-webkit-box-orient': portfolio && 'vertical',
  },
}))

