import { Box, styled } from '@mui/material'

export const IndicatorsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1.5),
}))

export const Indicator = styled(Box)<IIndicatorProps>(
  ({ theme, fill = 0, barcolor = null, backgroundColor = null }) => ({
    backgroundColor: backgroundColor ?? theme.palette.surface['800'],
    flexGrow: 1,
    height: theme.spacing(0.5),
    borderRadius: theme.spacing(1),
    position: 'relative',
    cursor: 'pointer',
    '&::after': {
      content: '""',
      width: `${fill}%`,
      height: '100%',
      borderRadius: theme.spacing(1),
      position: 'absolute',
      left: 0,
      top: 0,
      backgroundColor: barcolor ?? theme.palette.primary['500'],
      pointerEvents: 'none',
    },
  }),
)
