import { Box, styled } from '@mui/material'

export const ProjectCanvasContainer = styled(Box)(({ theme }) => ({
  height: '300px',
  width: `calc(100% + ${theme.spacing(6)})`,
  marginLeft: `-${theme.spacing(3)}`,
  zIndex: 0,
  [theme.breakpoints.up('sm')]: {
    height: '400px',
    width: `calc(100% + ${theme.spacing(10)})`,
    marginLeft: `-${theme.spacing(5)}`,
  },
  [theme.breakpoints.up('md')]: {
    width: `calc(100% + ${theme.spacing(16)})`,
    marginLeft: `-${theme.spacing(8)}`,
  },
  [theme.breakpoints.up('lg')]: {
    position: 'absolute',
    width: `calc(100% + ${theme.spacing(16)})`,
    height: '100%',
    left: `-${theme.spacing(8)}`,
    top: -80,
  },
}))
