/* eslint react/no-unknown-property: 0 */

import { memo, useEffect, useRef } from 'react'

import { Canvas, Scene } from 'components/3DScene'

import { ProjectCanvasContainer } from './styled'

import ProjectDevice from './ProjectDevice'

const ProjectCanvas = ({ currentProject, inView, getCurrentVideoLength }: IProjectCanvas) => {
  const videoRef = useRef<HTMLVideoElement | null>(null)

  useEffect(() => {
    videoRef.current = document.createElement('video')
    videoRef.current.crossOrigin = 'anonymous'
    videoRef.current.loop = true
    videoRef.current.muted = true
    videoRef.current.controls = false
    videoRef.current.autoplay = true
    videoRef.current.playsInline = true
  }, [])

  return (
    <ProjectCanvasContainer>
      <Canvas>
        <ambientLight intensity={-0.8} />
        <spotLight position={[0, 10, 40]} intensity={0.5} />
        <Scene
          cameraProps={{ position: [0, 0.5, 45], near: 0.1, far: 100, zoom: 3 }}
          spotlightProps={{ position: [0, 20, 5], intensity: 2 }}
        />
        <ProjectDevice
          getCurrentVideoLength={getCurrentVideoLength}
          currentProject={currentProject}
          videoRef={videoRef}
          inView={inView}
        />
      </Canvas>
    </ProjectCanvasContainer>
  )
}

export default memo(ProjectCanvas)
