import { Box, Skeleton, styled } from '@mui/material'

export const SkeletonContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
})

export const StyledSkeleton = styled(Skeleton)(({ theme }) => ({
  backgroundColor: theme.palette.surface['900'],
}))

export const IndicatorSkeleton = styled(Skeleton)(({ theme }) => ({
  backgroundColor: theme.palette.surface['900'],
  width: '150px',
  height: '4px',
}))

export const VerticalSkeleton = styled(Skeleton)(({ theme }) => ({
  backgroundColor: theme.palette.surface['900'],
  width: '100%',
  height: '500px',
  borderRadius: theme.spacing(2),
  [theme.breakpoints.up('md')]: {
    height: '440px',
  },
  [theme.breakpoints.up('lg')]: {
    height: '400px',
  },
}))
