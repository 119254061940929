import { Box, styled } from '@mui/material'

export const ProjectsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  zIndex: 1,
  padding: theme.spacing(0, 0, 0),
  [theme.breakpoints.up('lg')]: {
    height: 'calc(100vh - 80px)',
    maxHeight: '800px',
    paddingBottom: theme.spacing(8),
  },
}))

export const IndicatorsContainer = styled(Box)(({ theme }) => ({
  margin: theme.spacing(0, 0, 3),
  zIndex: 1,
  [theme.breakpoints.up('md')]: {
    order: 2,
  },
  [theme.breakpoints.up('lg')]: {
    marginTop: 'auto',
  },
}))
