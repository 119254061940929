import { Typography, useMediaQuery, Theme } from '@mui/material'

import { SkeletonContainer, StyledSkeleton, IndicatorSkeleton, VerticalSkeleton } from './styled'
import {
  VerticalsIndicatorsContainer,
  VerticalsIndicatorContainer,
} from '../VerticalsIndicators/styled'

export default function Loading() {
  const isLg = useMediaQuery<Theme>((theme) => theme.breakpoints.up('lg'))
  return (
    <SkeletonContainer>
      <Typography variant="h2" sx={{ mb: 4 }}>
        <StyledSkeleton variant="rectangular" width={200} />
      </Typography>
      {isLg && (
        <VerticalsIndicatorsContainer>
          {[...Array(6)].map((_, index) => (
            <VerticalsIndicatorContainer key={index}>
              <Typography variant="h6">
                <StyledSkeleton variant="rectangular" width={150} />
              </Typography>
              <IndicatorSkeleton variant="rectangular" />
            </VerticalsIndicatorContainer>
          ))}
        </VerticalsIndicatorsContainer>
      )}
      <VerticalSkeleton variant="rectangular" />
    </SkeletonContainer>
  )
}
