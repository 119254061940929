import { useQuery } from '@baseapp-frontend/core'
import ProjectsApi from 'api/ProjectsApi'

import { IUseProjects, IUseProjectsParams } from './types'

export function useProjects(params: IUseProjectsParams): IUseProjects {
  const { data, isLoading, ...rest } = useQuery(params.queryKey || '/projects', () =>
    ProjectsApi.getProjects(params.apiParams || {}),
  )

  return {
    projects: data?.results || [],
    isLoadingProjects: isLoading,
    ...rest,
  }
}
