import { fontSize } from '@baseapp-frontend/design-system-mui'
import { styled, Typography } from '@mui/material'

export const Value = styled(Typography)(({ theme }) => ({
  ...theme.typography.h4,
  [theme.breakpoints.up('lg')]: {
    ...theme.typography.h2,
    lineHeight: fontSize(44),
  },
})) as typeof Typography

export const ValueSymbol = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  marginLeft: 0.25,
  [theme.breakpoints.up('lg')]: {
    ...theme.typography.h5,
  },
})) as typeof Typography

export const Description = styled(Typography)(({ theme }) => ({
  ...theme.typography.body2,
  [theme.breakpoints.up('lg')]: {
    ...theme.typography.subtitle1,
  },
})) as typeof Typography
