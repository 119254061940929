import { useQuery } from '@baseapp-frontend/core'
import SkillsApi from 'api/SkillsApi'

import { IUseSkills, IUseSkillsParams } from './types'

export function useSkills(params: IUseSkillsParams): IUseSkills {
  const { data, isLoading, ...rest } = useQuery(params.queryKey || '/skills', () =>
    SkillsApi.getSkills(),
  )

  return {
    skills: data?.results || [],
    isloadingSkills: isLoading,
    ...rest,
  }
}
