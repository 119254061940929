import { styled, Box, Typography } from '@mui/material'
import { preventForwardProps } from 'utils/styledUtils'
import { trustedByConstants } from './constants'
import { ITrustedByContainer } from './types'

export const TrustedByWrapper = styled(Box)(() => ({
  overflow: 'hidden',
  textAlign: 'center',
}))

export const TrustedByTitle = styled(Typography)(() => ({
  margin: '0 auto 36px',
})) as typeof Typography

export const TrustedByContainer = styled(
  Box,
  preventForwardProps(['finalWidth', 'elements', 'direction']),
)<ITrustedByContainer>(({ finalWidth, elements, direction = 'left' }) => {
  const initialTransform = direction === 'left' ? 'translateX(0)' : 'translateX(-50%)'
  const finalTransform = direction === 'left' ? 'translateX(-50%)' : 'translateX(0)'

  return {
    display: 'flex',
    width: `${finalWidth}%`,
    animation: `trustedByAnimation${direction} ${
      elements * trustedByConstants.timePerLogo
    }s linear infinite`,
    position: 'relative',
    overflow: 'hidden',
    [`@keyframes trustedByAnimation${direction}`]: {
      '0%': {
        transform: initialTransform,
      },
      '100%': {
        transform: finalTransform,
      },
    },
  }
})

export const GradientContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  '&::before, &::after': {
    background: 'linear-gradient(to right, rgba(0, 0, 0, 1) 90%, rgba(0, 0, 0, 0))',
    content: '""',
    height: '200px',
    width: 0,
    position: 'absolute',
    zIndex: 2,
    [theme.breakpoints.up(1280)]: {
      width: 'calc((100vw - 1280px)*0.5)',
    },
  },
  '&::before': {
    left: 0,
    top: 0,
  },
  '&::after': {
    right: 0,
    top: 0,
    transform: 'rotateZ(180deg)',
  },
}))
