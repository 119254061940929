import { useEffect, useRef } from 'react'
import { Theme, useMediaQuery } from '@mui/material'
import { useQuery } from '@baseapp-frontend/core'
import { useInView } from 'framer-motion'

import Vertical from './Vertical'
import Loading from './Loading'
import VerticalsIndicators from './VerticalsIndicators'
import Container from 'components/Container'
import Carousel from 'components/TimedCarousel'
import useTimedCarousel from 'components/TimedCarousel/hooks/useTimedCarousel'
import { VerticalsContainer, VerticalsTitle } from './styled'
import { VerticalsIndicatorsContainer } from './VerticalsIndicators/styled'

import VerticalsApi from 'api/VerticalsApi'

const CAROUSEL_DELAY = 10000 // ms

export default function Verticals() {
  const containerRef = useRef(null)

  const isLg = useMediaQuery<Theme>((theme) => theme.breakpoints.up('lg'))

  const { data, isFetching } = useQuery('verticals', () => VerticalsApi.getVerticals(), {
    cacheTime: 0,
  })
  const verticals = data?.results || []

  const inView = useInView(containerRef, { amount: 0.5 })

  const {
    currentSlide,
    currentTimePercentage,
    prevSlide,
    nextSlide,
    setSlide,
    pauseTimer,
    resumeTimer,
  } = useTimedCarousel(verticals.length, () => CAROUSEL_DELAY)

  useEffect(() => {
    if (inView) {
      resumeTimer()
    } else {
      pauseTimer()
    }
  }, [inView, pauseTimer, resumeTimer])

  return (
    <Container>
      <VerticalsContainer ref={containerRef}>
        {isFetching || !verticals.length ? (
          <Loading />
        ) : (
          <>
            <VerticalsTitle component="h2">Verticals</VerticalsTitle>
            {isLg && (
              <VerticalsIndicatorsContainer>
                <VerticalsIndicators
                  verticals={verticals}
                  currentSlide={currentSlide}
                  currentTimePercentage={currentTimePercentage}
                  setSlide={setSlide}
                />
              </VerticalsIndicatorsContainer>
            )}
            <Carousel index={currentSlide}>
              {verticals.map((vertical: IVertical) => (
                <Vertical
                  key={vertical.slug}
                  vertical={vertical}
                  controlsProps={{
                    leftDisabled: currentSlide === 0,
                    rightDisabled: currentSlide >= verticals.length - 1,
                    prevSlide,
                    nextSlide,
                    pauseTimer,
                    resumeTimer,
                  }}
                  currentTimePercentage={currentTimePercentage}
                />
              ))}
            </Carousel>
          </>
        )}
      </VerticalsContainer>
    </Container>
  )
}
