import { Box, Typography, Theme, useMediaQuery } from '@mui/material'
import Image from 'next/image'

import Button from 'components/Button'
import Controls from 'components/TimedCarousel/Controls'
import { Indicator } from '../../TimedCarousel/Indicators/styled'
import { scrollToElement } from 'utils/pageUtils'
import { contactFormId } from 'shared/constants'

import { VerticalsIndicatorsContainer } from '../VerticalsIndicators/styled'

import {
  LinearGradientBorderWrapper,
  VerticalsLinearGradientContainer,
  ImageGradientContainer,
  VerticalsContentContainer,
  CTAContainer,
  VerticalsDescription,
} from './styled'

export default function Vertical({
  vertical,
  controlsProps,
  currentTimePercentage,
}: IVerticalProps) {
  const isLg = useMediaQuery<Theme>((theme) => theme.breakpoints.up('lg'))

  const handleGetInTouchClick = () => {
    scrollToElement(`#${contactFormId}`, { block: 'center' })
  }

  return (
    <LinearGradientBorderWrapper
      onMouseEnter={controlsProps.pauseTimer}
      onMouseLeave={controlsProps.resumeTimer}
    >
      <VerticalsLinearGradientContainer>
        <VerticalsContentContainer>
          <Box component="div" display="flex" gap="16px">
            <Box
              component="div"
              sx={{
                width: 32,
                height: 32,
                position: 'relative',
              }}
            >
              <Image
                src={vertical.icon.normalSize}
                loader={({ src, width }) => `${src}?w=${width}`}
                layout="fill"
                alt={vertical.iconAltText || ''}
              />
            </Box>
            <Typography variant="h4" component="h3" sx={{ color: 'surface.50', mb: 2.5 }}>
              {vertical?.title}
            </Typography>
          </Box>
          {!isLg && (
            <VerticalsIndicatorsContainer>
              <Indicator fill={currentTimePercentage} flexGrow={1} barcolor="#EEFAFF" />
            </VerticalsIndicatorsContainer>
          )}
          {vertical?.description.split('\n').map((text, index) => (
            <VerticalsDescription component="p" key={index} variant="subtitle2">
              {text === '\r' ? <br /> : text}
            </VerticalsDescription>
          ))}
          <CTAContainer>
            <Controls containerProps={{ sx: { gap: 1 } }} {...controlsProps} />
            {vertical.learnMoreUrl && (
              <Button
                buttonvariant="dark"
                href={vertical.learnMoreUrl}
                title={`Learn More about ${vertical?.title}`}
              >
                Learn more
              </Button>
            )}
            {isLg ? (
              <Button hideicon="true" buttonvariant="light" onClick={handleGetInTouchClick}>
                Get in touch
              </Button>
            ) : null}
          </CTAContainer>
        </VerticalsContentContainer>
        <ImageGradientContainer>
          <Image
            src={vertical?.backgroundImage.normalSize}
            loader={({ src, width }) => `${src}?w=${width}`}
            layout="fill"
            objectFit="cover"
            objectPosition="15% 0%"
            alt={vertical.backgroundImageAltText}
          />
        </ImageGradientContainer>
      </VerticalsLinearGradientContainer>
    </LinearGradientBorderWrapper>
  )
}

