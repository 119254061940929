import { Euler, Vector3 } from 'three'

export const deviceProps = {
  mobile: {
    xs: {
      position: new Vector3(0, 0, 0),
      rotation: new Euler(-0.2, -0.4, -0.2),
      scale: 4.5,
    },
    sm: {
      position: new Vector3(0, 1, 0),
      rotation: new Euler(-0.2, -0.4, -0.2),
      scale: 4.5,
    },
    md: {
      position: new Vector3(0, 1, 0),
      rotation: new Euler(-0.2, -0.4, -0.2),
      scale: 4.5,
    },
    lg: {
      position: new Vector3(7, 0, 0),
      rotation: new Euler(-0.2, -0.4, -0.2),
      scale: 4,
    },
    xl: {
      position: new Vector3(7, 0, 0),
      rotation: new Euler(-0.4, -0.4, -0.3),
      scale: 4,
    },
  },
  mobile_landscape: {
    xs: {
      position: new Vector3(0, 0, 0),
      rotation: new Euler(-0.2, -0.4, -0.2),
      scale: 6,
    },
    sm: {
      position: new Vector3(0, 1, 0),
      rotation: new Euler(-0.2, -0.4, -0.2),
      scale: 6,
    },
    md: {
      position: new Vector3(0, 1, 0),
      rotation: new Euler(-0.2, -0.4, -0.2),
      scale: 7,
    },
    lg: {
      position: new Vector3(6, 0, 0),
      rotation: new Euler(-0.2, -0.4, -0.2),
      scale: 4,
    },
    xl: {
      position: new Vector3(7, 0, 0),
      rotation: new Euler(-0.4, -0.4, -0.3),
      scale: 4,
    },
  },
  tablet: {
    xs: {
      position: new Vector3(0, 1, 0),
      rotation: new Euler(-0.5, -0.1, -0.2),
      scale: 6.5,
    },
    sm: {
      position: new Vector3(0, 1, 0),
      rotation: new Euler(-0.5, -0.1, -0.2),
      scale: 6.5,
    },
    md: {
      position: new Vector3(0, 1, 0),
      rotation: new Euler(-0.5, -0.1, -0.2),
      scale: 6.5,
    },
    lg: {
      position: new Vector3(6, 0.5, 0),
      rotation: new Euler(-0.2, -0.3, -0.2),
      scale: 4.5,
    },
    xl: {
      position: new Vector3(7, 0.5, 0),
      rotation: new Euler(-0.2, -0.3, -0.2),
      scale: 4.5,
    },
  },
  desktop: {
    xs: {
      position: new Vector3(0, 0.5, 0),
      rotation: new Euler(0, 0, 0),
      scale: 5,
    },
    sm: {
      position: new Vector3(0, 0, 0),
      rotation: new Euler(0, 0, 0),
      scale: 6,
    },
    md: {
      position: new Vector3(0, 0.4, 0),
      rotation: new Euler(0, 0, 0),
      scale: 5.5,
    },
    lg: {
      position: new Vector3(5, 0, 0),
      rotation: new Euler(-0.03, -0.5, 0),
      scale: 4,
    },
    xl: {
      position: new Vector3(5, 0, 0),
      rotation: new Euler(-0.03, -0.5, 0),
      scale: 4.5,
    },
  },
}
