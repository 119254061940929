import { useState, useCallback, useEffect } from 'react'

interface ITimedCarouselHook {
  currentSlide: number
  currentTime: number
  currentTimePercentage: number
  nextSlide: () => void
  prevSlide: () => void
  setSlide: (slide: number) => void
  pauseTimer: () => void
  resumeTimer: () => void
}

export default function useTimedCarousel(
  slideCount = 0,
  delayForSlide: (slide: number) => number,
  initialSlideIndex = 0,
): ITimedCarouselHook {
  const [currentSlide, setCurrentSlide] = useState(initialSlideIndex)
  const [currentTime, setCurrentTime] = useState(0)
  const [pauseInterval, setPauseInterval] = useState(true)
  const [currentDelay, setCurrentDelay] = useState(delayForSlide(0))

  useEffect(() => {
    const _delay = delayForSlide(currentSlide) // ?? delay
    setCurrentDelay(_delay)
    const updateRate = _delay / 125

    let interval: NodeJS.Timer | null = null

    if (!pauseInterval && slideCount > 1) {
      interval = setInterval(() => {
        setCurrentTime((prevState) => prevState + updateRate)
      }, updateRate)
    }

    return () => {
      if (interval) clearInterval(interval)
    }
  }, [pauseInterval, slideCount, currentSlide, delayForSlide])

  const resetTimer = useCallback(() => {
    setCurrentTime(0)
  }, [])

  const nextSlide = useCallback(() => {
    setCurrentSlide((prevState) => {
      const next = prevState + 1

      return next < slideCount ? next : 0
    })
  }, [slideCount])

  const prevSlide = useCallback(() => {
    setCurrentSlide((prevState) => {
      const prev = prevState - 1

      return prev >= 0 ? prev : slideCount - 1
    })
  }, [slideCount])

  const setSlide = useCallback(
    (slide: number) => {
      setCurrentSlide(slide)
      resetTimer()
    },
    [setCurrentSlide, resetTimer],
  )

  const handleNextSlide = useCallback(() => {
    resetTimer()
    nextSlide()
  }, [nextSlide, resetTimer])

  const handlePrevSlide = useCallback(() => {
    resetTimer()
    prevSlide()
  }, [prevSlide, resetTimer])

  const pauseTimer = useCallback(() => {
    setPauseInterval(true)
  }, [])

  const resumeTimer = useCallback(() => {
    setPauseInterval(false)
  }, [])

  if (currentTime >= currentDelay) {
    handleNextSlide()
  }

  return {
    currentSlide,
    currentTime,
    currentTimePercentage: (currentTime / currentDelay) * 100,
    nextSlide: handleNextSlide,
    prevSlide: handlePrevSlide,
    setSlide,
    pauseTimer,
    resumeTimer,
  }
}
