import { Box, styled } from '@mui/material'

export const AvatarContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  height: '220px',
  width: '220px',
  borderRadius: '50%',
  overflow: 'hidden',
  flexShrink: 0,
  gap: theme.spacing(2),
  [theme.breakpoints.down('md')]: {
    height: '150px',
    width: '150px',
  },
  [theme.breakpoints.down('sm')]: {
    marginRight: 0,
    height: '130px',
    width: '130px',
  },
}))
