import { Indicator, IndicatorsContainer } from './styled'

export default function Indicators({
  count,
  currentSlide,
  currentTimePercentage,
  setSlide = () => null,
  barcolor,
  backgroundColor,
}: IIndicatorsProps) {
  const getIndicatorFill = (index: number) => {
    if (index < currentSlide) {
      return 100
    }

    if (index === currentSlide) {
      return currentTimePercentage
    }

    return 0
  }

  return (
    <IndicatorsContainer>
      {[...Array(count)].map((_, index) => (
        <Indicator
          key={index}
          fill={getIndicatorFill(index)}
          onClick={() => setSlide(index)}
          barcolor={barcolor}
          backgroundColor={backgroundColor}
        />
      ))}
    </IndicatorsContainer>
  )
}
