import { ButtonsContainerDesktop, ContentContainer, TestimonialContainer } from './styled'
import { FC } from 'react'
import Profile from '../Profile'
import Content from '../Content'
import Buttons from '../Buttons'
import { Theme, useMediaQuery } from '@mui/material'

const Testimonial: FC<ITestimonialProps> = ({ testimonial, controlsProps, indicators }) => {
  const upMd = useMediaQuery<Theme>((theme) => theme.breakpoints.up('md'))

  return (
    <TestimonialContainer>
      {upMd && (
        <ButtonsContainerDesktop sx={{ marginRight: 4 }}>
          <Buttons controlsProps={controlsProps} leftSide />
        </ButtonsContainerDesktop>
      )}

      <ContentContainer>
        <Profile testimonial={testimonial} controlsProps={controlsProps} />
        <Content testimonial={testimonial} indicators={indicators} />
      </ContentContainer>

      {upMd && (
        <ButtonsContainerDesktop sx={{ marginLeft: 'auto' }}>
          <Buttons controlsProps={controlsProps} />
        </ButtonsContainerDesktop>
      )}
    </TestimonialContainer>
  )
}

export default Testimonial
