import Stats from './Stats'
import Button from 'components/Button'
import Controls from 'components/TimedCarousel/Controls'
import {
  ControlsContainer,
  ControlsWrapper,
  CTAContainer,
  ProjectWrapper,
  Subtitle,
  Tagline,
  Title,
} from './styled'

import { scrollToElement } from 'utils/pageUtils'
import { contactFormId } from 'shared/constants'
import { IProjectProps } from './types'

export default function Project({
  project,
  hideCarouselControls = false,
  controlsProps,
  portfolio = false,
}: IProjectProps) {
  const handleGetInTouchClick = () => {
    scrollToElement(`#${contactFormId}`, { block: 'center' })
  }

  return (
    <ProjectWrapper>
      {project.subtitle && (
        <Subtitle variant="h6" component="p">
          {project.subtitle}
        </Subtitle>
      )}
      <Title component="h2">{project.title}</Title>
      <Tagline portfolio={portfolio}>{project.tagline}</Tagline>

      <Stats projectStats={project.stats || []} portfolio={portfolio} />

      <ControlsWrapper>
        <CTAContainer>
          <Button
            buttonvariant="dark"
            href={`/portfolio/${project.slug}`}
            title={`Learn More about ${project.title}`}
          >
            Learn More
          </Button>
          <Button buttonvariant="cta2" onClick={handleGetInTouchClick} title="Get in touch">
            Get in touch
          </Button>
        </CTAContainer>
        {!hideCarouselControls && (
          <ControlsContainer>
            <Controls {...controlsProps} />
          </ControlsContainer>
        )}
      </ControlsWrapper>
    </ProjectWrapper>
  )
}
